import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteReferenceService {

  quoteReference = new BehaviorSubject<string>("") 

  constructor() { }

  setQuoteReference(quoteReference: string){
    
    this.quoteReference.next(quoteReference)
    
  }
}
