import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';

import { MaterialModule } from './modules/material/material.module';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { DATE_PIPE_DEFAULT_OPTIONS } from "@angular/common";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    MaterialModule,
    HttpClientModule,
    CommonModule,
    NgxMaskDirective,
    NgxMaskPipe,
    GoogleTagManagerModule.forRoot({
      id: 'GTM-7DRT',
    })
  ],
  providers: [provideAnimations(),
    provideEnvironmentNgxMask(),
    {provide: 'googleTagManagerId',  useValue: 'GTM-7DRT'},
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS,
      useValue: { dateFormat: "longDate" }
    }],
  bootstrap: [AppComponent],
})
export class AppModule {}
