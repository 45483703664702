<footer>
  <div class="logo-container">
    <img
      src="../../../../../assets/images/chill-logo-purple.svg"
      alt=""
      srcset=""
    />

    <span>
      <!-- TrustBox widget -->
      <!-- <div class="trustpilot-widget" data-locale="en-GB" data-template-id="539ad60defb9600b94d7df2c" data-businessunit-id="57eb89ea0000ff0005954a7d" data-style-height="100px" data-style-width="100%" data-theme="light" data-stars="3,4,5" data-schema-type="Organization">
        <a href="https://uk.trustpilot.com/review/chill.ie" target="_blank" rel="noopener">Trustpilot</a>
      </div> -->
      <!-- End TrustBox widget -->
       <!-- TrustBox widget - Micro Star -->
       <div class="trustpilot-widget" data-locale="en-IE" data-template-id="5419b732fbfb950b10de65e5"
       data-businessunit-id="57eb89ea0000ff0005954a7d" data-style-height="24px" data-style-width="100%"
       data-theme="light"> <a href="https://ie.trustpilot.com/review/chill.ie" target="_blank"
         rel="noopener">Trustpilot</a> </div> <!-- End TrustBox widget -->
    </span>
  </div>
  <hr />
  <div class="copyright-container">
    <span
      >Copyright @ Chill Insurance. All Rights Reserved. Chill Insurance Ltd,
      Chill Insurance is regulated by the Central Bank of Ireland. Registered
      number 506021.Registered in Republic of Ireland. Terms of Business,
      Privacy Policy.</span
    >
   
      <!-- OneTrust Cookies Settings button start -->
      <button  matRipple
      [matRippleCentered]="true"
      [matRippleDisabled]="false"
      [matRippleUnbounded]="false" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
      <!-- OneTrust Cookies Settings button end -->
    
  </div>
</footer>
