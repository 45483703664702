import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JourneyPageComponent } from './modules/journey/pages/journey-page/journey-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./modules/journey/journey.module').then((m) => m.JourneyModule),
  },
  {
    path: 'quote',
    loadChildren: () =>
      import('./modules/quote/quote.module').then((m) => m.QuoteModule),
  },
  // {
  //   path: 'quote/assumption',
  //   loadChildren: () =>
  //     import('./modules/quote/quote.module').then((m) => m.QuoteModule),
  // },
  {
    path: 'payment',
    loadChildren: () =>
      import('./modules/payment/payment.module').then((m) => m.PaymentModule),
  },
  {
    path: 'retrieve',
    loadChildren: () =>
      import('./modules/retrieve/retrieve.module').then((m) => m.RetrieveModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
