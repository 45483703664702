import { Component, inject, OnInit } from '@angular/core';
import { QuoteReferenceService } from 'src/app/services/quote-reference.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit{

  private quoteReferenceService = inject(QuoteReferenceService)

  quoteReference: string = ""

  ngOnInit(): void {
    this.quoteReferenceService.quoteReference.subscribe((value: string)=>{

      this.quoteReference = value

    })
  }


}
